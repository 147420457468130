/* ShowConcertInfo.css */
.band-info-container-bandinfo {
    width: 1210px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    margin: 2px auto;
}

.blurred-poster-bandinfo {
    width: 1210px;
    height: 1000px;
    object-fit: cover;
    filter: blur(3px);
    transform: scale(1.1);
}
.band-avatar-and-follow {
    display: flex;
    align-items: center;
    /* 根据需要添加其他样式 */
}

.follow-button {
    border-radius: 50px;
    width: 60px;
}

.poster-overlay-bandinfo {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
}

.clear-poster-container-bandinfo {
    position: absolute;
    left: 50px;
    top: 50px;
    right: 50px;
    width: 200px;
    height: 250px;
    overflow: hidden;
}

.clear-poster-bandinfo {
    width: 200px;
    height: 200px;
    border-radius: 50%; /* 保持头像圆形 */
    object-fit: cover;
}

.band-genre-container-bandinfo {
    position: absolute;
    top: 150px;
    left: 300px;
    color: white;
    font-size: 20px;
    width: 30%; /* 设置容器宽度，确保有足够空间显示多行文本 */
    word-wrap: break-word; /* 允许单词在需要时断行 */
    overflow-wrap: break-word; /* 在长单词或 URL 地址内部断行 */
}
.band-name-container-bandinfo {
    position: absolute;
    top: 14%; /* 将容器移动到垂直中心 */
    left: 50%; /* 将容器移动到水平中心 */
    transform: translate(-50%, -50%); /* 精确定位容器的中心点 */
    color: white;
    font-size: 30px;
    width: 50%; /* 设置容器宽度，确保有足够空间显示多行文本 */
    word-wrap: break-word; /* 允许单词在需要时断行 */
    overflow-wrap: break-word; /* 在长单词或 URL 地址内部断行 */
}

.text-container-bandinfo {
    min-width: 150px;
}
@media only screen and (max-width: 768px){
    /* ShowConcertInfo.css */
    .band-info-container-bandinfo {
        width: 99%;
        height: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        position: relative;
        margin: 2px auto;
    }

    .blurred-poster-bandinfo {
        width: 100%;
        height: auto;
        object-fit: cover;
        filter: blur(3px);
        transform: scale(1.1);
    }

    .poster-overlay-bandinfo {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.6);
    }

    .clear-poster-container-bandinfo {

        top: 20%;      /* 垂直居中 */
        left: 50%;     /* 水平居中 */
        transform: translate(-50%, -50%); /* 精确定位容器的中心点 */
        width: 150px;
        height: 150px;
        overflow: hidden;
    }

    .clear-poster-bandinfo {
        width: 150px;
        height: 150px;
        border-radius: 50%; /* 保持头像圆形 */
        object-fit: cover;
    }

    .band-name-container-bandinfo {
        position: absolute;
        top: 45%; /* 微调位置，稍微向下 */
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-size: 20px;
        width: 100%;
        word-wrap: break-word;
        overflow-wrap: break-word;
        text-align: center; /* 文本居中 */
    }

    .band-genre-container-bandinfo {
        position: absolute;
        top: 60% ; /* 微调位置，稍微向下 */
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-size: 20px;
        width: 100%;
        word-wrap: break-word;
        overflow-wrap: break-word;
        text-align: center; /* 文本居中 */
    }

    .text-container-bandinfo {
        min-width: 150px;
    }
}
@media only screen and (min-width: 769px) and (max-width: 1209px){
    .band-info-container-bandinfo {
        width: 100%;
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        position: relative;
        margin: 2px auto;
    }

    .blurred-poster-bandinfo {
        width: 100%;
        height: 1000px;
        object-fit: cover;
        filter: blur(3px);
        transform: scale(1.1);
    }

    .poster-overlay-bandinfo {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.6);
    }

    .clear-poster-container-bandinfo {
        position: absolute;
        left: 50px;
        top: 50px;
        right: 50px;
        width: 200px;
        height: 250px;
        overflow: hidden;
    }

    .clear-poster-bandinfo {
        width: 200px;
        height: 200px;
        border-radius: 50%; /* 保持头像圆形 */
        object-fit: cover;
    }

    .band-genre-container-bandinfo {
        position: absolute;
        top: 150px;
        left: 300px;
        color: white;
        font-size: 20px;
        width: 30%; /* 设置容器宽度，确保有足够空间显示多行文本 */
        word-wrap: break-word; /* 允许单词在需要时断行 */
        overflow-wrap: break-word; /* 在长单词或 URL 地址内部断行 */
    }
    .band-name-container-bandinfo {
        position: absolute;
        top: 50px; /* 将容器移动到垂直中心 */
        left: 50%; /* 将容器移动到水平中心 */
        transform: translate(-50%, -50%); /* 精确定位容器的中心点 */
        color: white;
        font-size: 30px;
        width: 20%; /* 设置容器宽度，确保有足够空间显示多行文本 */
        word-wrap: break-word; /* 允许单词在需要时断行 */
        overflow-wrap: break-word; /* 在长单词或 URL 地址内部断行 */
    }

    .text-container-bandinfo {
        min-width: 150px;
    }
}