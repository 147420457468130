.future-pass-concert {
    width: 1210px;
    height: 80px;
    background-color: white;
}

/* 按钮样式 */
.future-pass-concert button {
    width: 100px;
    height: 50px;
    border: none; /* 去掉外边框 */
    outline: none;
    background-color: transparent;
    color: gray;
    font-weight: normal;
    margin-left: 25px;
    margin-top: 15px;
}

/* 激活状态按钮样式 */
.future-pass-concert button.active {
    font-weight: bold;
    background-color: transparent; /* 保持透明背景 */
    color: black;
    border-bottom: 2px solid black; /* 激活状态下的底部边框 */
}


@media only screen and (min-width: 769px) and (max-width: 1209px){

    .future-pass-concert {
        width: 100vw;
        height: 80px;
        background-color: white;
    }
    .ConcertDetailsForSearchWrapper01{
        width: 100vw;
    }
}

@media only screen and (max-width: 768px){

    .future-pass-concert{
        width: 100vw;
        height: auto;
        background-color: white;
        padding-bottom: 15px;
        display: flex;
        justify-content: center; /* 水平居中 */
        align-items: center; /* 垂直居中 */
    }

    .ConcertDetailsForSearchWrapper01{
        width: 100vw;
        height: auto;
        background-color: white;
        padding-bottom: 15px;
        display: flex;
        justify-content: center; /* 水平居中 */
        align-items: center; /* 垂直居中 */
    }

}