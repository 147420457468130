.website {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; /* 居中子元素 */
    background: #CCCCCC;
}

.profile-container {
    background-color: white; /* 设置背景颜色为白色 */
    width: 1210px; /* 设置宽度为 1210px */
    display: flex; /* 使用 Flexbox 布局 */
    flex-direction: column; /* 设置 Flexbox 方向为垂直 */
    align-items: center; /* 水平居中 */
    justify-content: center; /* 垂直居中 */
    /* 根据需要添加 padding 和 margin */
}

/* 媒体查询，适应中等屏幕 */
@media screen and (max-width: 1209px) {
    .profile-container {
        width: 100%; /* 当屏幕宽度小于等于 1209px 时，宽度设置为 100% */
    }
}

/* 媒体查询，适应小屏幕 */
@media screen and (max-width: 768px) {
    .profile-container {
        /* 可以在这里添加特定的样式，适应小屏幕 */
    }
}
