.Concert-DetailsForSearch-Wrapper {
    margin-top: 2px;
    background-color: white;
    width: 1210px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Concert-DetailsForSearch-Wrapper-noconcert{
    background-color: white;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.pagination{
    margin-bottom: 5px;
    margin-top: 5px;
}
.search-result-header-SearchResultConcert {
    background-color: white;
    text-align: left;
    padding: 25px;
    width: 100%;
    height: auto;
    margin-top: 2px;
}
@media only screen and (max-width: 768px) {
    .Concert-DetailsForSearch-Wrapper {
        background-color: white;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .Concert-DetailsForSearch-Wrapper-noconcert{
        background-color: white;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
@media only screen and (min-width: 769px) and (max-width: 1209px){
    .Concert-DetailsForSearch-Wrapper {
        background-color: white;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .Concert-DetailsForSearch-Wrapper-noconcert{
        background-color: white;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}