.concert-card{
    border: 1px solid #EDEDED; /* 黑色边框 */
    min-width: 250px;
}
.concert-poster {
    width: 250px;
    height: 300px;
    display: block;
    object-fit: contain;
    border-radius: 8px 8px 0 0;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    transition: transform 0.3s ease; /* 添加过渡效果 */
}

.concert-poster:hover {
    transform: scale(1.03); /* 放大1.05倍 */
}
.concert-info {
    width: 250px;
    height: 150px;
    padding-bottom: 0;
    padding-top: 1%;
    color: #333;
    display: flex; /* 使用 flex 布局 */
    flex-direction: column; /* 元素垂直排列 */
    position: relative; /* 设置为相对定位 */
}

.concert-date {
    margin-left: 10px;
    font-size: 0.8em ;
    margin-top: 5px;
    margin-bottom: 5px; /* 调整底部外边距 */
    text-align: left; /* 左对齐 */
}

.concert-name {
    width: 230px;
    height: 50px;
    margin-left: 10px;
    font-size: 1em;
    margin-bottom: 2px;
    padding-left: 2px;
    padding-right: 5px;
    text-align: center;
    color: black;
    cursor: pointer;

    overflow: hidden; /* 隐藏超出元素的内容 */
    text-overflow: ellipsis; /* 当文本溢出时显示省略号 */
}


.concert-location {
    margin-left: 10px;
    font-size: 0.8em;
    color: #666;
    text-align: left;
    margin-top: auto;
    margin-bottom: 1%; /* 调整底部外边距 */
}
