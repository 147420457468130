
.concert-details-box {
    position: relative; /* 设置为相对定位的容器 */
    padding: 5px;
    shadow: md;
    border-width: 1px;
    width: 1160px;
    height: 250px;
    background: white;
}

.flex-direction {
    align-items: start;
}

.pastpastimage-box {
    display: flex; /* 使用flex布局 */
    align-items: center; /* 垂直居中 */
    justify-content: center; /* 水平居中 */
    margin-bottom: 2px; /* base */
    margin-top: 2px; /* base */
    margin-right: 2px; /* md */
    width: 180px;
    height: 232px;
}
.pastpastimage-box img {
    max-width: 100%; /* 图片最大宽度为容器宽度 */
    max-height: 100%; /* 图片最大高度为容器高度 */
    object-fit: contain; /* 图片将保持其宽高比 */
}

.pastpastimage-box-md {
    max-width: 300px; /* md */
}

.pastheading {
    font-size: 20px;
    padding-left: 15px; /* 根据需要调整 */
}

.text-lg {
    font-size: large;
}

.margin-top {
    margin-top: 4px;
}
/* 在ConcertDetailsForSearch.css中添加 */

.bottom-info {
    position: absolute; /* 绝对定位 */
    bottom: 6px; /* 设置距离底部的位置 */
    width: 600px;
    display: flex;
    justify-content: space-between;
    padding-left: 15px; /* 根据需要调整 */
    padding-right: 15px; /* 根据需要调整 */
}

.info-item {
    display: flex;
    align-items: center;
}

.icon {
    margin-right: 8px;
}
.artistName{
    padding-left: 15px; /* 根据需要调整 */
}

@media only screen and (min-width: 769px) and (max-width: 1209px){
    /* ConcertDetailsForSearch.css */

    .concert-details-box {
        position: relative; /* 设置为相对定位的容器 */
        padding: 5px;
        shadow: md;
        border-width: 1px;
        width: 95%;
        height: 250px;
        background: white;
    }

    .flex-direction {
        align-items: start;
    }

    .pastpastimage-box {
        display: flex; /* 使用flex布局 */
        align-items: center; /* 垂直居中 */
        justify-content: center; /* 水平居中 */
        margin-bottom: 2px; /* base */
        margin-top: 2px; /* base */
        margin-right: 2px; /* md */
        width: 180px;
        height: 232px;
    }
    .pastpastimage-box img {
        max-width: 100%; /* 图片最大宽度为容器宽度 */
        max-height: 100%; /* 图片最大高度为容器高度 */
        object-fit: contain; /* 图片将保持其宽高比 */
    }

    .pastpastimage-box {
        max-width: 300px; /* md */
    }

    .pastheading {
        font-size: 20px;
        padding-left: 15px; /* 根据需要调整 */
    }

    .text-lg {
        font-size: large;
    }

    .margin-top {
        margin-top: 4px;
    }
    /* 在ConcertDetailsForSearch.css中添加 */

    .bottom-info {
        position: absolute; /* 绝对定位 */
        bottom: 6px; /* 设置距离底部的位置 */
        width: 600px;
        display: flex;
        justify-content: space-between;
        padding-left: 15px; /* 根据需要调整 */
        padding-right: 15%; /* 根据需要调整 */
    }

    .info-item {
        display: flex;
        align-items: center;
    }

    .icon {
        margin-right: 8px;
    }
    .artistName{
        padding-left: 15px; /* 根据需要调整 */
    }
}
@media only screen and (max-width: 768px) {

    .concert-details-box {
        position: relative; /* 设置为相对定位的容器 */
        padding: 5px;
        shadow: md;
        border-width: 1px;
        width: 100%;
        height: 200px;
        background: white;
    }

    .flex-direction {
        align-items: start;
    }

    .pastimage-box {
        display: none;
    }
    .pastimage-box img {
        max-width: 100%; /* 图片最大宽度为容器宽度 */
        max-height: 100%; /* 图片最大高度为容器高度 */
        object-fit: contain; /* 图片将保持其宽高比 */
    }

    .pastimage-box-md {
        max-width: 300px; /* md */
    }

    .pastheading {
        font-size: 15px !important;
        padding-left: 10px; /* 根据需要调整 */
    }

    .text-lg {
        font-size: 10px;
    }

    .margin-top {
        margin-top: 4px;
    }
    /* 在ConcertDetailsForSearch.css中添加 */

    .bottom-info {
        position: absolute; /* 绝对定位 */
        bottom: 6px; /* 设置距离底部的位置 */
        width: 100%;
        display: flex;
        padding-left: 10px; /* 根据需要调整 */
        padding-right: 10px; /* 根据需要调整 */
        flex-direction: column; /* 改为列布局，使内容垂直排列 */
        justify-content: center; /* 水平居中对齐（在列布局中影响垂直对齐） */
        align-items: center; /* 确保文本和按钮在列布局中水平居中 */
        flex-wrap: wrap; /* 允许内容在必要时换行 */
    }

    .info-item {
        display: flex;
        align-items: center;
    }

    .icon {
        margin-right: 8px;
    }
    .artistName{
        padding-left: 10px; /* 根据需要调整 */
    }
}