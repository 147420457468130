/* UpcomingConcerts.css */

.concerts-cards-container {
    display: flex;
    overflow-x: auto;
    flex-wrap: nowrap;
    width: 100%;
    height: 490px;
    gap: 5px;
    padding-bottom: 5px;
    margin-bottom: 5px;
}


.concerts-title {
    font-size: 18px ;
    margin-top: 10px;
    margin-bottom: 0; /* 添加这行来调整间距 */
}

.concerts-detail {
    font-size: 15px ;
    padding-bottom: 10px;
    color: #666;
}


.view-all-button {
    display: block;
    margin-left: auto;
    margin-right: 10px;
}

@media only screen and (max-width: 1209px) {

    .concerts-cards-container {
        display: flex;
        overflow-x: auto;
        flex-wrap: nowrap;
        width: 100%;
        height: 480px;
        gap: 5px;
        padding-bottom: 5px;
        margin-bottom: 5px;
    }

}