/* NoConcerts.css */
.noConcerts {
    width: 1210px;
    height: 500px;
    background-color: grey;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto; /* 使组件在其父元素中居中 */
}
@media only screen and (max-width: 1209px){

    .noConcerts {
        width: 100%;
        height: 500px;
        background-color: grey;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto; /* 使组件在其父元素中居中 */
    }
}
