.website {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.registration-container {
    width: 1210px;
    height: auto;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: adds shadow for better appearance */
    margin-top: 20px;
    margin-bottom: 20px;

}

/* 给所有输入框添加灰色边框 */
.registration-container input {
    border: 1px solid gray; /* 设置边框颜色和宽度 */
    padding: 8px; /* 可选：增加内边距以提高输入框的可读性和美观 */
    margin: 5px 0; /* 可选：增加输入框周围的间隔 */
    border-radius: 4px; /* 可选：为输入框添加圆角 */
}

/* 在获得焦点时改变边框颜色 */
.registration-container input:focus {
    border-color: #007bff; /* 当输入框获得焦点时的边框颜色 */
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25); /* 可选：添加聚焦阴影效果 */
}

.registration-container form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px; /* Adjust the space between form elements */
}

.registration-container form label {
    display: flex;
    flex-direction: column; /* 确保标签内元素垂直排列 */
    align-items: flex-start; /* 从头开始对齐 */
    gap: 5px;
}
.registration-container form label h2 {
    min-width: 120px; /* Adjust as needed for your layout */
}
.password-container {
    display: flex;
    align-items: center;
}

.password-container .show-password-button {
    margin-left: 10px;
}
/* 修改注册按钮的样式 */
.registration-container form button[type="registerbutton"] {
    background-color: #FF0033; /* 设置按钮背景颜色为红色 */
    color: white; /* 设置按钮文字颜色为白色 */
    border: none; /* 移除边框 */
    padding: 10px 20px; /* 设置内边距，增加按钮大小 */
    border-radius: 20px; /* 设置边框半径，使两端为圆形 */
    cursor: pointer; /* 将鼠标悬停时的指针样式设置为手指形状 */
    font-weight: bold; /* 可选：设置字体加粗 */
    margin-top: 10px; /* 可选：在按钮上方添加一些空间 */
}

/* 修改按钮在鼠标悬停时的样式 */
.registration-container form button[type="registerbutton"]:hover {
    background-color: #CC0033; /* 鼠标悬停时的背景颜色 */
}
.message-container {
    height: 20px; /* 根据需要调整高度 */
    color: red;
    font-size: 14px; /* 可根据需要调整字体大小 */
}
@media only screen and (min-width: 769px) and (max-width: 1209px) {
    .registration-container {
        width: 100%;
        height: auto;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: adds shadow for better appearance */
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

/* 媒体查询 */
@media only screen and (max-width: 768px) {
    .registration-container {
        width: 100%;
        height: auto;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: adds shadow for better appearance */
        margin-top: 20px;
        margin-bottom: 20px;
    }
}