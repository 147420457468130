/* BandCard.css */
.band-avatar {
    border-radius: 50%; /* 保持头像圆形 */
    width: 200px; /* 将头像的宽度设置为 200px */
    height: 200px; /* 将头像的高度设置为 200px */
    margin-right: 20px; /* 可能需要调整右边距 */
    object-fit: cover; /* 保持宽高比，覆盖整个元素区域 */
    cursor: pointer;
}
.band-avatar:hover {
    transform: scale(1.03); /* 放大1.03倍 */
}


.band-name {
    font-size: 1em; /* 根据需要调整字体大小 */
    margin-top: 20px; /* 调整与头像的间距 */
    margin-bottom: 10px; /* 调整名字和按钮之间的间距 */
    cursor: pointer;
    color: black;
}

.band-card-container {
    max-width: 100%; /* 适应父容器宽度 */
    padding-left: 60px;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex; /* 使用 flex 布局 */
    align-items: center; /* 垂直居中元素 */
}

.follow-button {
    margin-top: 20px; /* 调整按钮与其他元素的间距 */
    font-size: 1em; /* 可以调整按钮字体大小 */
}

@media only screen and (max-width: 767px){

    /* BandCard.css */
    .band-avatar {
        border-radius: 50%; /* 保持头像圆形 */
        width: 80px; /* 将头像的宽度设置为 200px */
        height: 80px; /* 将头像的高度设置为 200px */
        margin-right: 20px; /* 可能需要调整右边距 */
        object-fit: cover; /* 保持宽高比，覆盖整个元素区域 */
        cursor: pointer;
    }

    .band-name {
        font-size: 1em; /* 根据需要调整字体大小 */
        margin-top: 10px; /* 调整与头像的间距 */
        margin-bottom: 10px; /* 调整名字和按钮之间的间距 */
        cursor: pointer;
        color: black;
    }

    .band-card-container {
        max-width: 100%; /* 适应父容器宽度 */
        padding-left: 60px;
        padding-top: 20px;
        padding-bottom: 20px;
        display: flex; /* 使用 flex 布局 */
        align-items: center; /* 垂直居中元素 */
    }

    .follow-button {
        margin-top: 10px; /* 调整按钮与其他元素的间距 */
        font-size: 1em; /* 可以调整按钮字体大小 */

    }

}

@media only screen and (min-width: 769px) and (max-width: 1209px){
    .band-avatar {
        border-radius: 50%; /* 保持头像圆形 */
        width: 100px; /* 将头像的宽度设置为 100px */
        height: 100px; /* 将头像的高度设置为 100px */
        margin-right: 20px; /* 可能需要调整右边距 */
        object-fit: cover; /* 保持宽高比，覆盖整个元素区域 */
        cursor: pointer;
    }
    .band-avatar:hover {
        transform: scale(1.03); /* 放大1.03倍 */
    }


    .band-name {
        font-size: 1em; /* 根据需要调整字体大小 */
        margin-top: 20px; /* 调整与头像的间距 */
        margin-bottom: 10px; /* 调整名字和按钮之间的间距 */
        cursor: pointer;
        color: black;
    }

    .band-card-container {
        max-width: 100%; /* 适应父容器宽度 */
        padding-left: 60px;
        padding-top: 20px;
        padding-bottom: 20px;
        display: flex; /* 使用 flex 布局 */
        align-items: center; /* 垂直居中元素 */
    }

    .follow-button {
        margin-top: 20px; /* 调整按钮与其他元素的间距 */
        font-size: 1em; /* 可以调整按钮字体大小 */
    }
}
