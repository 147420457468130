
.website-SearchResultConcert {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; /* 居中子元素 */
    background: #CCCCCC;
}
.searchHeading {
    background-image: url('../img/HeaderImg/imgforsearch.png'); /* Replace with your image path */
    background-size: cover;
    background-position: center;
    width: 1210px;
    height: 300px;
}

.searchHeading-text {
    color: white; /* Choose your text color */
    font-size: 48px; /* Adjust the font size as needed */
    padding: 50px; /* Adjust the padding as needed */
}


@media only screen and (min-width: 769px) and (max-width: 1209px){
    .searchHeading {
        background-image: url('../img/HeaderImg/imgforviewall.png'); /* Replace with your image path */
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 300px;
    }

    .searchHeading-text01 {
        color: white; /* Choose your text color */
        font-size: 48px; /* Adjust the font size as needed */
        padding: 50px; /* Adjust the padding as needed */
    }
}
@media only screen and (max-width: 768px){
    .searchHeading {
        background-image: url('../img/HeaderImg/imgforviewall.png'); /* Replace with your image path */
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 200px;
    }

    .searchHeading-text01 {
        color: white; /* Choose your text color */
        font-size: 36px; /* Adjust the font size as needed */
        padding: 20px; /* Adjust the padding as needed */
    }
}