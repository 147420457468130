.website {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; /* 居中子元素 */
    background: #CCCCCC;
}
.login-container {
    width: 1210px;
    background-color: white;
    margin: 20px auto; /* 水平居中并添加上下边距 */
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 轻微阴影 */
    border-radius: 10px; /* 边框圆角 */
    text-align: center; /* 新增：使容器内的所有元素居中 */
}

.login-form {
    display: flex;
    flex-direction: column;
    align-items: center; /* 水平居中表单元素 */
    width: 100%; /* 设置宽度以确保子元素能够正确居中 */
}

.form-group {
    margin-bottom: 15px; /* 增加表单元素之间的间距 */
}

.form-group label {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* 标签对齐方式 */
}

.form-group input {
    width: 300px; /* 输入框宽度 */
    padding: 10px;
    margin-top: 5px; /* 标签与输入框间距 */
    border: 1px solid #ccc;
    border-radius: 5px;
}

.login-sumbimt-button {
    background-color: #FF0033; /* 设置按钮背景颜色为红色 */
    color: white; /* 设置按钮文字颜色为白色 */
    border: none; /* 移除边框 */
    padding: 10px 20px; /* 设置内边距，增加按钮大小 */
    border-radius: 20px; /* 设置边框半径，使两端为圆形 */
    cursor: pointer; /* 将鼠标悬停时的指针样式设置为手指形状 */
    font-weight: bold; /* 字体加粗 */
    margin-top: 10px; /* 在按钮上方添加一些空间 */
    font-size: 16px; /* 设置合适的字体大小 */
}

.error {
    color: red;
    margin-top: 10px;
}
@media only screen and (min-width: 769px) and (max-width: 1209px) {
    .login-container {
        width: 100%;
        background-color: white;
        margin: 20px auto; /* 水平居中并添加上下边距 */
        padding: 20px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 轻微阴影 */
        border-radius: 10px; /* 边框圆角 */
        text-align: center; /* 新增：使容器内的所有元素居中 */
    }
}

/* 媒体查询 */
@media only screen and (max-width: 768px) {
    .login-container {
        width: 100%;
        background-color: white;
        margin: 20px auto; /* 水平居中并添加上下边距 */
        padding: 20px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 轻微阴影 */
        border-radius: 10px; /* 边框圆角 */
        text-align: center; /* 新增：使容器内的所有元素居中 */
    }
}