@import "~react-responsive-carousel/lib/styles/carousel.min.css";

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@font-face {
  font-family: 'NotoSansSC-Regular';
  src: local('NotoSansSC-Regular'), url('./assets/fonts/static/NotoSansSC-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSansSC-Bold';
  src: local('NotoSansSC-Bold'), url('./assets/fonts/static/NotoSansSC-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'NotoSansSC-Light';
  src: local('NotoSansSC-Light'), url('./assets/fonts/static/NotoSansSC-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'NotoSansSC-ExtraBold';
  src: local('NotoSansSC-ExtraBold'), url('./assets/fonts/static/NotoSansSC-ExtraBold.ttf') format('truetype');
}

/* 应用字体 */
h1 {
  font-family: 'NotoSansSC-Light', cursive;
}
h2 {
  font-family: 'NotoSansSC-Regular', cursive;
}
h3 {
  font-family: 'NotoSansSC-Bold', cursive;
}
h4 {
  font-family: 'NotoSansSC-ExtraBold', cursive;
}
