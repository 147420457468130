/* ShowConcertInfo.css */
.concert-info-container {
    width: 1210px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    margin: 2px auto;
}

.blurred-poster {
    width: 1400px;
    height: 1000px;
    object-fit: cover;
    filter: blur(3px);
    transform: scale(1.1);
}

.poster-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
}

.clear-poster-container-concert {
    position: absolute;
    top: 50px;
    right: 50px;
    width: 200px;
    height: 250px;
    overflow: hidden;
}

.clear-poster-concert {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.band-name-container {
    position: absolute;
    top: 180px !important; /* 将容器移动到垂直中心 */
    left: 305px !important; /* 将容器移动到水平中心 */
    color: white;
    font-size: 20px;
    width: 700px; /* 设置容器宽度，确保有足够空间显示多行文本 */
    word-wrap: break-word; /* 允许单词在需要时断行 */
    overflow-wrap: break-word; /* 在长单词或 URL 地址内部断行 */
}
.concert-title-container {
    position: absolute;
    top: 30px; /* 将容器移动到垂直中心 */
    left: 305px; /* 将容器移动到水平中心 */
    color: white;
    font-size: 30px;
    width: 600px; /* 设置容器宽度，确保有足够空间显示多行文本 */
    word-wrap: break-word; /* 允许单词在需要时断行 */
    overflow-wrap: break-word; /* 在长单词或 URL 地址内部断行 */
}

.date-container, .location-container {
    display: flex;
    align-items: center;
    position: absolute;
}

.date-container {
    bottom: 10%;
    left: 10%;
    color: white;
    font-size: 24px;
}

.location-container {
    bottom: 10%;
    left: 40%;
    color: white;
    font-size: 24px;
}

.icon-container {
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
}

.text-container {
    min-width: 150px;
}

@media only screen and (max-width: 768px){
    .concert-info-container {
        width: 100%;
        height: 700px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        position: relative;
        margin: 2px auto;
    }

    .blurred-poster {
        width: 100%;
        height: auto;
        object-fit: cover;
        filter: blur(3px);
        transform: scale(1.1);
    }

    .poster-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.6);
    }

    .clear-poster-container-concert {
        position: absolute;
        top: 50%;       /* 垂直方向中心 */
        left: 50%;      /* 水平方向中心 */
        width: 250px;
        height: 350px;
        overflow: hidden;
        transform: translate(-50%, -50%); /* 向上和向左移动元素的50%，确保准确居中 */
    }

    .clear-poster-concert {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .band-name-container {
        display: none;
    }
    .concert-title-container {
        position: absolute;
        top: 10% ; /* 将容器移动到垂直中心 */
        left: 55% ; /* 将容器移动到水平中心 */
        transform: translate(-50%, -50%); /* 向上和向左移动元素的50%，确保准确居中 */
        color: white;
        font-size: 30px;
        width: 90%; /* 设置容器宽度，确保有足够空间显示多行文本 */
        word-wrap: break-word; /* 允许单词在需要时断行 */
        overflow-wrap: break-word; /* 在长单词或 URL 地址内部断行 */
    }

    .date-container, .location-container {
        display: flex;
        align-items: center;
        position: absolute;
    }

    .date-container {

        top: 83% !important; /* 将容器移动到垂直中心 */
        left: 50% !important; /* 将容器移动到水平中心 */
        transform: translate(-50%, -50%); /* 向上和向左移动元素的50%，确保准确居中 */
        color: white;
        font-size: 24px;
    }

    .location-container {
        width: 90%;
        top: 92% !important; /* 将容器移动到垂直中心 */
        left: 50% !important; /* 将容器移动到水平中心 */
        transform: translate(-50%, -50%); /* 向上和向左移动元素的50%，确保准确居中 */
        color: white;
        font-size: 24px;
    }

    .icon-container {
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 8px;
    }

    .text-container {
        min-width: 150px;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1209px){
    .concert-info-container {
        width: 100%;
        height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        position: relative;
        margin: 2px auto;
    }

    .blurred-poster {
        width: 1400px;
        height: 1000px;
        object-fit: cover;
        filter: blur(3px);
        transform: scale(1.1);
    }

    .poster-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.6);
    }

    .clear-poster-container-concert {
        position: absolute;
        top: 50px;
        right: 50px;
        width: 200px;
        height: 250px;
        overflow: hidden;
    }

    .clear-poster-concert {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .band-name-container {
        display: none;
    }
    .concert-title-container {
        position: absolute;
        top: 30px; /* 将容器移动到垂直中心 */
        left: 10%; /* 将容器移动到水平中心 */
        color: white;
        font-size: 30px;
        width: 600px; /* 设置容器宽度，确保有足够空间显示多行文本 */
        word-wrap: break-word; /* 允许单词在需要时断行 */
        overflow-wrap: break-word; /* 在长单词或 URL 地址内部断行 */
    }

    .date-container, .location-container {
        display: flex;
        align-items: center;
        position: absolute;
    }

    .date-container {
        bottom: 10%;
        left: 10%;
        color: white;
        font-size: 24px;
    }

    .location-container {
        bottom: 10%;
        left: 40%;
        color: white;
        font-size: 24px;
    }

    .icon-container {
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 8px;
    }

    .text-container {
        min-width: 150px;
    }
}