.footer {
    background-color: #3A3A3A; /* 设置背景颜色 */
    color: #ffffff; /* 设置文字颜色为白色以确保可读性 */
    text-align: center;
    padding: 20px;
    position: relative;
    bottom: 0;
    width: 100%;
}

.footer-content {
    margin-top: 20px;
}
