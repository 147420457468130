/* GetTickets.css */
.get-tickets-container {
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    flex-wrap: wrap; /* 允许内容在必要时换行 */
    background-color: white;
    padding: 10px;
    margin-bottom: 5px;
    width: 1210px;
    height: 100px;
}

.text-and-buttons-container {
    display: flex;
    align-items: center; /* 确保文本和按钮在同一行垂直居中 */
    flex-wrap: wrap; /* 允许内容在必要时换行 */
    gap: 20px; /* 在文本和按钮之间添加一些间隙 */
    font-size: 1.5em;
    color: black;
}

@media only screen and (min-width: 769px) and (max-width: 1209px){
    .get-tickets-container {
        display: flex;
        justify-content: center; /* 水平居中 */
        align-items: center; /* 垂直居中 */
        flex-wrap: wrap; /* 允许内容在必要时换行 */
        background-color: white;
        padding: 10px;
        margin-bottom: 5px;
        width: 100%;
        height: 100px;
    }

    .text-and-buttons-container {
        display: flex;
        align-items: center; /* 确保文本和按钮在同一行垂直居中 */
        flex-wrap: wrap; /* 允许内容在必要时换行 */
        gap: 20px; /* 在文本和按钮之间添加一些间隙 */
        font-size: 1.5em;
        color: black;
    }
}

@media only screen and (max-width: 769px){
    .get-tickets-container {
        display: flex;
        justify-content: center; /* 水平居中 */
        align-items: center; /* 垂直居中 */
        flex-wrap: wrap; /* 允许内容在必要时换行 */
        background-color: white;
        padding: 10px;
        margin-bottom: 5px;
        width: 100%;
        height: auto;
    }

    .text-and-buttons-container {
        display: flex;
        flex-direction: column; /* 改为列布局，使内容垂直排列 */
        justify-content: center; /* 水平居中对齐（在列布局中影响垂直对齐） */
        align-items: center; /* 确保文本和按钮在列布局中水平居中 */
        flex-wrap: wrap; /* 允许内容在必要时换行 */
        gap: 20px; /* 在文本和按钮之间添加一些间隙 */
        font-size: 1.5em;
        color: black;
    }
}

