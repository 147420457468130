/* ArtistSuggestions.css */

.artist-suggestions-box {
    position: absolute;
    z-index: 1;
    background-color: white;
    border: 2px solid #ccc;
    width: 500px;
    height: auto;
    margin-top: 0;
    top: calc(100% + 15px);
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px; /* 设置边框圆角为 10px */
}

.suggestion-item {
    padding: 2px;
    display: flex;
    align-items: center;
    margin: 10px;
}

.suggestion-image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 2px;
}

.suggestion-text {
    color: black;
    font-size: large;
    white-space: nowrap; /* 保持文本在一行内 */
    overflow: hidden; /* 隐藏超出容器的内容 */
    text-overflow: ellipsis; /* 超出部分显示为省略号 */
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 20px; /* 设置一个较大的图标大小 */
    color: black !important; /* 设置图标颜色 */
    background-color: white; /* 设置背景颜色以突出显示 */
    border-radius: 50%; /* 圆形背景 */
    padding: 5px; /* 添加一些内边距 */
}
@media only screen  and (min-width: 769px) and (max-width: 1209px) {
    /* ArtistSuggestions.css */
    .artist-suggestions-box {
        position: absolute;
        z-index: 1;
        background-color: white;
        border: 2px solid #ccc;
        width: 50%;
        height: auto;
        margin-top: 0;
        top: calc(100% + 10px);
        left: 55%;
        transform: translateX(-50%);
    }

}

@media only screen and (max-width: 768px) {
    /* ArtistSuggestions.css */

    .artist-suggestions-box {
        position: absolute;
        z-index: 1;
        background-color: white;
        border: 2px solid #ccc;
        width: 90%;
        height: auto;
        margin-top: 0;
        top: calc(100% + 10px);
        left: 50%;
        transform: translateX(-50%);
    }
}