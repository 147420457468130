/* AllConcerts.css */

.all-concerts-container {
    width: 1210px;
    justify-content: center; /* Center items horizontally */
    align-items: center; /* Center items vertically */
    display: flex;
    flex-wrap: wrap;
    gap:34px; /* 设置卡片之间的间距 */
    background-color: white; /* 添加背景色为白色 */
}
.style-buttons {
    width: 1210px;
    padding-left: 50px;
    padding-right: 50px;
    margin-top: auto; /* 将按钮组推到容器底部 */
    display: flex;
    gap: 10px; /* 按钮之间的间距 */
    flex-wrap: wrap; /* 允许按钮换行 */
}

.style-buttons button.selected {
    background-color: #336699; /* 选中按钮的背景色 */
    color: white; /* 选中按钮的文字颜色 */
    /* 其他需要的样式 */
}

.style-buttons button {
    border: 1px solid #336699; /* 添加黑色边框 */
    padding: 5px 15px; /* 调整填充以适应按钮文本，可以根据需要调整 */
    background-color: white; /* 设置按钮背景颜色 */
    color: #336699; /* 设置按钮文本颜色 */
    cursor: pointer; /* 改变鼠标指针样式 */
    transition: background-color 0.2s, color 0.2s; /* 平滑颜色变化效果 */
    border-radius: 15px; /* 设置边框半径，使按钮两端圆形 */
}

.style-buttons button:hover {
    background-color: black; /* 鼠标悬停时的背景颜色 */
    color: white; /* 鼠标悬停时的文本颜色 */
}



.style-buttons-container {
    width: 1210px; /* 与演出卡片容器宽度一致 */
    height: auto;
    margin: 0 auto; /* 水平居中 */
    padding: 20px 0; /* 添加上下填充 */
    display: flex;
    justify-content: center; /* 水平居中显示按钮 */
    gap: 10px; /* 按钮之间的间距 */
    background-color: white; /* 背景色 */
}
.concert-card {
    flex: 0 0 calc(20% - 20px); /* 让每个卡片占据四分之一的宽度，并减去间距 */
    max-width: calc(25% - 20px); /* 设置卡片的最大宽度，以便在窗口缩小时不会溢出 */
}

/* AllConcerts.css */

.pagination-container {
    width: 1400px;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: white;
    display: flex;
    justify-content: center;
    padding: 10px;
    box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.1); /* 可选：添加阴影效果 */
}

.pagination {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    background-color: white;
}

.page-button {
    background-color: white; /* 背景颜色 */
    color: #333; /* 文字颜色 */
    border: 1px solid #CCCCCC; /* 灰色边框 */
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 5px;
    transition: background-color 0.2s;
}

.page-button:hover {
    background-color: #ddd; /* 悬停时背景色稍微变亮 */
}

.active {
    background-color: #CCCCCC; /* 活动页码的背景色可以与之前的样式保持一致 */
    color: white; /* 文字颜色变白 */
    border: 1px solid #CCCCCC; /* 边框颜色更深 */
}

@media only screen and (max-width: 768px){
    /* AllConcerts.css */
    .all-concerts-container {
        width: 100%;
        padding-left: 5px;
        padding-right: 5px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap:5px; /* 设置卡片之间的间距 */
        background-color: white; /* 添加背景色为白色 */
    }

    .style-buttons {
        width: 100%;
        padding-left:5%;
        padding-right: 5%;
        margin-top: auto; /* 将按钮组推到容器底部 */
        display: flex;
        gap: 10px; /* 按钮之间的间距 */
        flex-wrap: wrap; /* 允许按钮换行 */
    }

    .style-buttons button.selected {
        background-color: #336699; /* 选中按钮的背景色 */
        color: white; /* 选中按钮的文字颜色 */
        /* 其他需要的样式 */
    }


    .style-buttons button:hover {
        background-color: black; /* 鼠标悬停时的背景颜色 */
        color: white; /* 鼠标悬停时的文本颜色 */
    }

    .style-buttons-container {
        width: 100%; /* 与演出卡片容器宽度一致 */
        height: auto;
        margin: 0 auto; /* 水平居中 */
        padding: 20px 0; /* 添加上下填充 */
        display: flex;
        justify-content: center; /* 水平居中显示按钮 */
        gap: 10px; /* 按钮之间的间距 */
        background-color: white; /* 背景色 */
    }
    .concert-card {
        flex: 0 0 calc(20% - 20px); /* 让每个卡片占据四分之一的宽度，并减去间距 */
        max-width: calc(25% - 20px); /* 设置卡片的最大宽度，以便在窗口缩小时不会溢出 */
    }
    .pagination-container {
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        background-color: white;
        display: flex;
        justify-content: center;
        padding: 10px;
        box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.1); /* 可选：添加阴影效果 */
    }

}
@media only screen and (min-width: 769px) and (max-width: 1209px){

    .all-concerts-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center; /* Center items horizontally */
        align-items: center; /* Center items vertically */
        gap: 10px;
        background-color: white;
    }
    .style-buttons {
        width: 100%;
        padding-left: 50px;
        margin-top: auto; /* 将按钮组推到容器底部 */
        display: flex;
        gap: 10px; /* 按钮之间的间距 */
        flex-wrap: wrap; /* 允许按钮换行 */
    }

    .style-buttons button.selected {
        background-color: #336699; /* 选中按钮的背景色 */
        color: white; /* 选中按钮的文字颜色 */
        /* 其他需要的样式 */
    }

    .style-buttons button {
        border: 1px solid #336699; /* 添加黑色边框 */
        padding: 5px 15px; /* 调整填充以适应按钮文本，可以根据需要调整 */
        background-color: white; /* 设置按钮背景颜色 */
        color: #336699; /* 设置按钮文本颜色 */
        cursor: pointer; /* 改变鼠标指针样式 */
        transition: background-color 0.2s, color 0.2s; /* 平滑颜色变化效果 */
        border-radius: 15px; /* 设置边框半径，使按钮两端圆形 */
    }

    .style-buttons button:hover {
        background-color: black; /* 鼠标悬停时的背景颜色 */
        color: white; /* 鼠标悬停时的文本颜色 */
    }



    .style-buttons-container {
        width: 100%;
        height: auto;
        margin: 0 auto; /* 水平居中 */
        padding: 20px 0; /* 添加上下填充 */
        display: flex;
        justify-content: center; /* 水平居中显示按钮 */
        gap: 10px; /* 按钮之间的间距 */
        background-color: white; /* 背景色 */
    }
    .concert-card {
        flex: 0 0 calc(20% - 20px); /* 让每个卡片占据四分之一的宽度，并减去间距 */
        max-width: calc(25% - 20px); /* 设置卡片的最大宽度，以便在窗口缩小时不会溢出 */
    }

    .pagination-container {
        width: 99%;
        position: fixed;
        bottom: 0;
        left: 0;
        background-color: white;
        display: flex;
        justify-content: center;
        padding: 10px;
        box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.1); /* 可选：添加阴影效果 */
    }

    .pagination {
        padding-top: 10px;
        padding-bottom: 10px;
        display: flex;
        justify-content: center;
        background-color: white;
    }

    .page-button {
        background-color: white; /* 背景颜色 */
        color: #333; /* 文字颜色 */
        border: 1px solid #CCCCCC; /* 灰色边框 */
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin: 0 5px;
        transition: background-color 0.2s;
    }

    .page-button:hover {
        background-color: #ddd; /* 悬停时背景色稍微变亮 */
    }

    .active {
        background-color: #CCCCCC; /* 活动页码的背景色可以与之前的样式保持一致 */
        color: white; /* 文字颜色变白 */
        border: 1px solid #CCCCCC; /* 边框颜色更深 */
    }
}
