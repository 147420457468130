.website {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; /* 居中子元素 */
    background: #CCCCCC;
}

.custom-modal {
    position: fixed; /* Use fixed positioning */
    top: 50%;
    left: 50%;
    width: 250px;
    height: auto;
    transform: translate(-50%, -50%); /* Adjust to center the modal */
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.city-input-container h2 {
    font-size: larger; /* 调整文字大小 */
    padding-bottom: 10px; /* 添加底部内边距 */
}

.city-input-container input {
    width: 100%;
    background-color: white; /* 灰色背景 */
    padding-left: 34px; /* 留出空间放置图标 */
    font-size: 20px; /* 增加字体大小 */
    border: 2px solid grey; /* 灰色边框 */
    border-radius: 50px; /* 圆形边角 */
}

.city-input-container .search-icon {
    position: absolute;
    margin-left: 10px;
    margin-top: 5px;
    color: #888;
    font-size: 22px;
}
.upcoming-concerts-wrapper {
    width: 1210px;
    height: auto;
    margin: auto;
    padding-left: 25px; /* 左侧内边距 */
    padding-right: 25px; /* 右侧内边距 */
    background-color:#FFFFFD;
}

.city-button-container {
    display: flex;
    flex-wrap: wrap; /* 允许多行排列 */
    justify-content: center; /* 水平居中对齐 */
    gap: 5px;
}


.modal-style {
    width: 60% ;
    max-width: 90%;
    height: auto;
    max-height: 80%;
    margin: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow-y: auto; /* 允许垂直滚动 */
    /* 根据需要添加其他样式 */
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none; /* 移除边框 */
    background-color: transparent !important; /* 透明背景 */
    font-size: 20px; /* 调整字体大小 */
    cursor: pointer; /* 鼠标悬停时显示指针 */
}

.outline-city-button {
    margin-top: 10px;
    margin-left: 10px;
    width: auto;
    height: auto;
    font-size: 18px;
    border: 2px solid #CCCCCC;
    background-color: transparent;
    color: black;
    border-radius: 50px;
    /* 可以添加其他样式，如padding、border-radius等 */
}

.city-button{
    width: 80px;
    height: 30px;
    font-size: 15px;
    border: 2px solid gray;
    background-color: transparent;
    margin: 10px;
    color: black;
    border-radius: 50px;
}
.modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
    color: #333; /* 或者你喜欢的颜色 */
}
.welcome-background {
    width: 100%; /* 原内联样式中的宽度 */
    display: block;
    height: auto;
    max-height: 600px; /* 最大高度限制 */
}
.Discover-more-artist {
    background-image: url('../img/HeaderImg/findartist.png');
    background-size: cover; /* 或者根据需要调整 */
    background-position: center;
    height: 250px; /* 根据需要调整高度 */
    width: 1210px;
    display: flex;
    justify-content: flex-start; /* 修改此处以实现左对齐 */
    padding-left: 100px; /* 根据需要添加一些内边距以保持一定的间隙 */
    align-items: center;
    color: white; /* 根据背景图片调整文字颜色 */
    font-size: 24px; /* 根据需要调整文字大小 */
}
.discover-content {
    display: flex;
    flex-direction: column; /* 垂直排列 */
    align-items: center; /* 子元素在横向上中心对齐 */
}
.discover-more-button {
    margin-top: 10px; /* 按钮距离上方文本的距离 */
    padding: 10px 20px; /* 按钮内边距 */
    background-color: #FF0033; /* 按钮背景颜色，根据需要调整 */
    color: white; /* 按钮文字颜色 */
    border: none; /* 可选：无边框 */
    border-radius: 50px; /* 圆角 */
    cursor: pointer; /* 鼠标悬停时的手形指针 */
}


.welcome-background-container {
    position: relative;
    text-align: center; /* 使文字居中 */
}

.welcome-text {
    position: absolute;
    top: 44%; /* 调整这个值以改变文字在图片上的位置 */
    left: 50%;
    transform: translate(-50%, -50%);
    color: white; /* 设置文字颜色 */
}

.text-large {
    font-size: 36px;
}

.text-small {
    font-size: 24px;
}

/* 确保图片覆盖整个容器 */
.welcome-background {
    width: 1210px;
    height: auto;
}
.bottom-right-text {
    position: absolute;
    right: 3px; /* 右边距 */
    bottom: 3px; /* 底部距离 */
    color: white; /* 文字颜色 */
    font-size: 10px; /* 文字大小 */
}

@media only screen and (min-width: 769px) and (max-width: 1209px){

    .website {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center; /* 居中子元素 */
        background: #CCCCCC;
    }
    .upcoming-concerts-wrapper {
        width: 100%;
        height: auto;
        margin: auto;
        padding-left: 25px; /* 左侧内边距 */
        padding-right: 25px; /* 右侧内边距 */
        background-color:#FFFFFD;
    }
    .city-button {
        margin: 5px;
        padding: 5px 10px;
        /* 可以添加其他样式，如背景色、字体等 */
    }

    .city-button-container {
        display: flex;
        flex-wrap: wrap; /* 允许多行排列 */
        justify-content: center; /* 水平居中对齐 */
        gap: 10px;
    }

    .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        border: none; /* 移除边框 */
        background-color: transparent !important; /* 透明背景 */
        font-size: 20px; /* 调整字体大小 */
        cursor: pointer; /* 鼠标悬停时显示指针 */
    }
    .city-button{
        width: 80px;
        height: 30px;
        font-size: 13px;
        border: 2px solid #0099CC;
        background-color: transparent;
        color: #0099CC;
        border-radius: 50px;
    }
    .welcome-background {
        width: 100%; /* 原内联样式中的宽度 */
        display: block;
        height: auto;
        max-height: 600px; /* 最大高度限制 */
    }
    .Discover-more-artist {
        width: 100%;

    }
    .text-large {
        font-size: 24px;
    }

    .text-small {
        font-size: 18px;
    }

    /* 确保图片覆盖整个容器 */
    .welcome-background {
        width: 100%;
        height: auto;
    }
    .bottom-right-text {
        position: absolute;
        right: 3px; /* 右边距 */
        bottom: 3px; /* 底部距离 */
        color: white; /* 文字颜色 */
        font-size: 8px; /* 文字大小 */
    }

}
@media only screen and (max-width: 768px) {
    /* 769 */
    .website {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center; /* 居中子元素 */
        background: #CCCCCC;
    }
    .upcoming-concerts-wrapper {
        width: 100%;
        height: auto;
        margin: auto;
        padding-left: 25px; /* 左侧内边距 */
        padding-right: 25px; /* 右侧内边距 */
        background-color:#FFFFFD;
    }
    .city-button {
        margin: 10px;
        padding: 5px 10px;
        /* 可以添加其他样式，如背景色、字体等 */
    }

    .city-button-container {
        display: flex;
        flex-wrap: wrap; /* 允许多行排列 */
        justify-content: center; /* 水平居中对齐 */
        gap: 10px;
    }

    .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        border: none; /* 移除边框 */
        background-color: transparent !important; /* 透明背景 */
        font-size: 20px; /* 调整字体大小 */
        cursor: pointer; /* 鼠标悬停时显示指针 */
    }

    .hot-city-button {
        margin-top: 10px;
        width: 80px;
        height: 30px;
        font-size: 13px;
        border: 2px solid #FF6666;
        background-color: transparent;
        color: #FF6666;
        border-radius: 50px;
    }
    .city-button{
        width: 80px;
        height: 30px;
        font-size: 13px;
        border: 2px solid #0099CC;
        background-color: transparent;
        color: #0099CC;
        border-radius: 50px;
    }
    .welcome-background {
        width: 100%; /* 原内联样式中的宽度 */
        display: block;
        height: auto;
        max-height: 600px; /* 最大高度限制 */
    }
    .Discover-more-artist {
        width: 100%;
    }
    .text-large {
        font-size: 14px;
    }

    .text-small {
        font-size: 12px;
    }

    /* 确保图片覆盖整个容器 */
    .welcome-background {
        width: 100%;
        height: auto;
    }
    .bottom-right-text {
        position: absolute;
        right: 1px; /* 右边距 */
        bottom: 1px; /* 底部距离 */
        color: white; /* 文字颜色 */
        font-size: 6px; /* 文字大小 */
    }
}