.bands-container {
    position: relative;
    width: 1210px;
    display: flex;
    flex-direction: column; /* 改为列布局 */
    align-items: flex-start; /* 改为从左侧开始对齐 */
    background-color: #FFFFFD;
    padding: 25px;
}

.text-container {
    width: 100%; /* 宽度填满容器 */
    margin-bottom: 15px;
    text-align: left; /* 文本左对齐 */
}

.container-text-tittle-detail{
    font-size: 18px;
}
.band-card-container {
    flex-basis: calc(33.333%); /* 调整宽度和间距 */
    box-sizing: border-box;
}

/* 包含乐队卡片的容器，应用flex布局 */
.band-cards-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
}
.tittle-detail {
    font-size: 15px ;
    padding-bottom: 10px;
    color: #666;
}


@media only screen and (min-width: 769px) and (max-width: 1209px){
    .bands-container {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column; /* 改为列布局 */
        align-items: flex-start; /* 改为从左侧开始对齐 */
        background-color: #FFFFFD;
        padding: 25px;
    }

    .container-text {
        width: 100%; /* 宽度填满容器 */
        font-size: 18px;
        margin-bottom: 10px;
        text-align: left; /* 文本左对齐 */
    }

    .band-card-container {
        flex-basis: calc(33.333%); /* 调整宽度和间距 */
        padding-left: 3%;
        box-sizing: border-box;
    }

    /* 包含乐队卡片的容器，应用flex布局 */
    .band-cards-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        width: 100%;
    }
}
/* 媒体查询：当屏幕宽度小于 768 像素时 */
@media (max-width: 768px) {
    .band-card-container {
        flex-basis: 100%; /* 设置卡片宽度为100%，使每行只显示一个卡片 */
        padding-left: 20%; /* 可以移除左侧填充，如果需要 */
    }

    .bands-container {
        width: auto; /* 可以设置宽度为自动，以适应不同屏幕大小 */
    }
}