.concert-card-str2{
    border: 1px solid #EDEDED; /* 黑色边框 */
    min-width: 250px;
}
.concert-poster-str2 {
    width: 250px;
    height: 300px;
    display: block;
    object-fit: contain;
    border-radius: 8px 8px 0 0;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    transition: transform 0.3s ease; /* 添加过渡效果 */
}

.concert-poster-str2:hover {
    transform: scale(1.03); /* 放大1.05倍 */
}
.concert-info-str2 {
    width: 250px;
    height: 150px;
    padding-bottom: 0;
    padding-top: 1%;
    color: #333;
    display: flex; /* 使用 flex 布局 */
    flex-direction: column; /* 元素垂直排列 */
    position: relative; /* 设置为相对定位 */
}

.concert-date-str2 {
    margin-left: 10px;
    font-size: 0.8em ;
    margin-top: 5px;
    margin-bottom: 5px; /* 调整底部外边距 */
    text-align: left; /* 左对齐 */
}

.concert-name-str2 {
    width: 230px;
    height: 50px;
    margin-left: 10px;
    font-size: 1em;
    margin-bottom: 2px;
    padding-left: 2px;
    padding-right: 5px;
    text-align: center;
    color: black;
    cursor: pointer;

    overflow: hidden; /* 隐藏超出元素的内容 */
    text-overflow: ellipsis; /* 当文本溢出时显示省略号 */
}


.concert-location-str2 {
    margin-left: 10px;
    font-size: 0.8em;
    color: #666;
    text-align: left;
    margin-top: auto;
    margin-bottom: 1%; /* 调整底部外边距 */
}

@media only screen and (max-width: 768px){

    .concert-card-str2 {
        display: flex; /* 使用 flex 布局 */
        border: 1px solid #EDEDED; /* 黑色边框 */
        min-width: 80%;
        width: 100%;
    }

    .concert-poster-str2 {
        width: 100px;
        height: 150px;
        display: block;
        object-fit: contain;
        border-radius: 8px 0 0 8px; /* 修改左上角的圆角 */
        cursor: pointer;
        transition: transform 0.3s ease; /* 添加过渡效果 */
    }

    .concert-info-str2 {
        flex-grow: 1; /* 让 .concert-info 填满剩余空间 */
        padding: 1%;
        color: #333;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    .concert-date-str2 {
        margin-left: 10px;
        font-size: 0.8em ;
        margin-top: 5px;
        margin-bottom: 5px; /* 调整底部外边距 */
        text-align: left; /* 左对齐 */
    }

    .concert-name-str2 {
        width: 230px;
        height: 50px;
        margin-left: 10px;
        font-size: 1em;
        margin-bottom: 2px;
        padding-left: 2px;
        padding-right: 5px;
        text-align: center;
        color: black;
        cursor: pointer;

        overflow: hidden; /* 隐藏超出元素的内容 */
        text-overflow: ellipsis; /* 当文本溢出时显示省略号 */
    }


    .concert-location-str2 {
        margin-left: 10px;
        font-size: 0.8em;
        color: #666;
        text-align: left;
        margin-top: auto;
        margin-bottom: 1%; /* 调整底部外边距 */
    }

}