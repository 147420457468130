/* 容器基础样式 */
.otherlogin-container {
    max-width: 1200px; /* 最大宽度 */
    margin: 0 auto; /* 水平居中 */
    padding: 20px; /* 内边距 */
    box-sizing: border-box; /* 盒模型 */
}

/* 微信登录按钮样式 */
.wechat-login-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px; /* 或者你需要的高度 */
}
.wechat-login-button img {
    margin-right: 10px;
}
.loading-indicator {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #3498db;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* 媒体查询 */
@media only screen and (max-width: 768px) {
    .otherlogin-container {
        width: 100%; /* 最大宽度 */
        margin: 0 auto; /* 水平居中 */
        padding: 20px; /* 内边距 */
        box-sizing: border-box; /* 盒模型 */
    }
    .wechat-login-button button {
        font-size: 14px;
        padding: 5px 10px;
    }
    .wechat-login-button img {
        width: 20px;
        height: 20px;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1209px) {
    .otherlogin-container {
        width: 100%; /* 最大宽度 */
        margin: 0 auto; /* 水平居中 */
        padding: 20px; /* 内边距 */
        box-sizing: border-box; /* 盒模型 */
    }
    .wechat-login-button button {
        font-size: 16px;
        padding: 10px 15px;
    }
    .wechat-login-button img {
        width: 25px;
        height: 25px;
    }
}

